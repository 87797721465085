<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import { useSharedData, usePage } from '@consumer/composables/sharedData'
import { cssPropertiesForTheme, setCurrentTheme } from '@consumer/composables/themes'

import V2Header from '@consumer/modules/v2/layout/Header.vue'
import V2Footer from '@consumer/modules/v2/layout/Footer.vue'
import BaseLayout from '@consumer/layouts/BaseLayout.vue'

defineOptions({
  inheritAttrs: false,
})

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser'),
  currentTheme = _toRef(__$temp_1, 'currentTheme');

const __$temp_2 = (usePage()),
  component = _toRef(__$temp_2, 'component'),
  url = _toRef(__$temp_2, 'url');

const theme = setCurrentTheme()

const headerType = computed(() => {
  if ([
    'MultiPlaceGiftIdea',
    'BuyerLanding',
  ].includes(component.value)
    || url.value.startsWith('/my-account')
    || url.value.startsWith('/login')
    || url.value.startsWith('/signup')
  ) {
    return 'product'
  }
  else if (component.value === 'Checkout') {
    return 'checkout'
  }
  else if (
    ['RecipientGiftLandingV2'].includes(component.value)
    || url.value.startsWith('/support')
    || url.value.startsWith('/entercode')
    || url.value.startsWith('/card/activate')
  ) {
    return 'simple'
  }
  else {
    return 'default'
  }
})

const footerType = computed(() => {
  if (component.value === 'RecipientGiftLandingV2') {
    return false
  }
  else if (component.value === 'Checkout') {
    return 'checkout'
  }
  else if (
    url.value.startsWith('/support')
    || url.value.startsWith('/entercode')
    || url.value.startsWith('/card/activate')
  ) {
    return 'simple'
  }
  else {
    return 'default'
  }
})
</script>

<template>
  <Head>
    <title>Giftly: Online gift cards, printable gift cards, email gift cards, e-gift cards</title>
  </Head>

  <BaseLayout :style="cssPropertiesForTheme(theme)">
    <V2Header
      :type="headerType"
      :currentUser="currentUser"
      :currentTheme="currentTheme"
    />

    <div class="w-full relative flex-1 flex flex-col" data-qa="PageContent">
      <slot/>
    </div>

    <V2Footer
      v-if="footerType"
      :type="footerType"
    />
  </BaseLayout>
</template>
