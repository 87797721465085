<script setup lang="ts">
import HelpAndDisclosure from '@consumer/modules/v2/layout/HelpAndDisclosure.vue'
import { getYear } from 'date-fns'

const year = getYear(new Date())
</script>

<template>
  <div>
    <div class="w-full bg-surface-grey my-5">
      <HelpAndDisclosure lightTheme style="max-width: 1024px;"/>
    </div>
    <div
      class="w-full bg-surface-grey text-v2-tertiary
    flex flex-col gap-2 text-sm font-medium text-center px-2 py-8 text-balance"
    >
      <p>
        The Giftly Prepaid Gift is issued by Sunrise Banks N.A., St. Paul, MN 55103, Member FDIC.
      </p>
      <p>
        * Standard data rates, fees, and charges may apply to send by text.
      </p>
      <p>Copyright © {{ year }} Giftly. All rights reserved.</p>
    </div>
  </div>
</template>
